<template>
  <app-view>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary pb-50 mt-2">
      {{ $t('pages.plots.name') }}
    </h1>
    <h4
      v-if="plot !== null"
      class="text-ellipsis"
    > {{ plot.name }} {{ (plot.abbreviation+'').toLowerCase() !== (plot.name+'').toLowerCase() ? '(' + plot.abbreviation + ')' : '' }}</h4>

    <div class="mt-0 pt-75 mb-3">
      <template v-if="loader">
        <div class="mt-2 d-flex align-items-center justify-content-center my-5 py-2">
          <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
          />
        </div>
      </template>
      <template v-else>
        <div
          class="mt-1"
        >
          <b-row>
            <b-col
              class="mb-1"
            >
              <div
                v-if="loader"
                class="mt-4 mb-4 pt-3 pb-3 d-flex align-items-center justify-content-center flex-fill"
              >
                <b-spinner
                  variant="primary"
                  type="grow"
                  :label="$t('therms.loading')"
                />
              </div>
              <template v-else>
                <validation-observer
                  ref="addForm"
                  #default="{invalid}"
                >
                  <b-form
                    class="animate__animated"
                    :class="{'animate__shakeX':error}"
                    @submit.prevent="submit"
                  >
                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.name')+' : '"
                      label-for="name"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        vid="name"
                        rules="required|min:1|max:45"
                      >
                        <b-form-input
                          id="name"
                          v-model="name"
                          size="lg"
                          minlength="1"
                          maxlength="45"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('pages.plots.add-edit.forms.name.placeholder')"
                        />
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.abbreviation')+' : '"
                      label-for="abbreviation"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="abbreviation"
                        vid="abbreviation"
                        rules="required|max:5"
                      >
                        <b-form-input
                          id="abbreviation"
                          v-model="abbreviation"
                          name="abbreviation"
                          size="lg"
                          maxlength="5"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('pages.plots.add-edit.forms.abbreviation.placeholder')"
                        />
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.parent-plot')+' : '"
                      label-for="parent_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="parent_id"
                        vid="parent_id"
                      >
                        <b-form-select
                          id="parent_id"
                          v-model="parent_id"
                          size="lg"
                          :options="plotOptions"
                        />
                      </validation-provider>
                    </b-form-group>

                    <div class="mt-2 pt-75">
                      <touch-button
                        size="sm"
                        :disabled="invalid || error"
                        :disable="invalid || error"
                      >
                        {{ $t('therms.save') }}
                      </touch-button>
                      <touch-button
                        class="ml-75"
                        color="secondary"
                        size="sm"
                        :disabled="!hasChange"
                        @action.prevent="reset"
                      >
                        {{ $t('therms.cancel') }}
                      </touch-button>
                    </div>
                  </b-form>
                </validation-observer>
              </template>
            </b-col>
          </b-row>

        </div>
      </template>
    </div>
  </app-view>
</template>

<script>
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import {
  BSpinner,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { required, max, min } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Dialog } from '@capacitor/dialog'

export default {
  components: {
    NavButtonBar,
    NavButton,
    TouchButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      // ui
      error: false,
      loader: false,

      // data
      plot: null,
      name: '',
      abbreviation: '',
      parent_id: null,

      // validation rules
      required,
      max,
      min,
    }
  },
  computed: {
    ...mapGetters({
      has: 'plots/has',
      hierarchicalList: 'plots/getHierarchicalList',
    }),
    /**
     * @returns {Array<Plot>}
     */
    plotOptions() {
      const options = [{
        value: null,
        text: this.$t('therms.none'),
      }]

      if (this.has) {
        /**
         * @param {String} prefix
         * @param {Plot} plot
         */
        const addToOptions = (plot, prefix = '') => {
          const name = `${prefix} ${plot.name} ${plot.name !== plot.abbreviation ? `(${plot.abbreviation})` : ''}`.trim()

          options.push({
            value: plot.id,
            text: name,
          })

          if (addToOptions !== null && plot.subplots !== undefined && plot.subplots.length >= 1) {
            plot.subplots.forEach(e => addToOptions(e, `${name} > `.trim()))
          }
        }

        this.hierarchicalList
          .forEach(e => addToOptions(e))
      }

      return options
    },
    hasChange() {
      if (this.plot === null) return false
      return (this.plot.name !== this.name
          || this.plot.abbreviation !== this.abbreviation
          || this.plot.parent_id !== this.parent_id)
    },
  },
  watch: {
    error() {
      setTimeout(() => {
        if (this.error) {
          this.error = false
        }
      }, 800)
    },
  },
  async mounted() {
    this.error = false
    await this.load()
  },
  methods: {
    async load() {
      // eslint-disable-next-line radix
      this.plot = await this.$store.dispatch('plots/find', { id: parseInt(this.$router.currentRoute.params?.id) })

      this.name = this.plot.name
      this.abbreviation = this.plot.abbreviation
      this.parent_id = this.plot.parent_id
    },
    reset() {
      if (this.plot === null) return

      this.name = this.plot.name
      this.abbreviation = this.plot.abbreviation
      this.parent_id = this.plot.parent_id
    },
    async submit() {
      try {
        if (this.plot === null) throw new Error('Cannot retrieve plot to edit.')

        this.loader = true
        const result = await this.$store.dispatch('plots/edit', {
          id: this.plot.id,
          name: this.name,
          abbreviation: this.abbreviation,
          parent_id: this.parent_id,
        })

        if (!result) {
          this.error = true
          await Dialog.alert({
            title: this.$t('therms.error').toString(),
            message: this.$t('actions.plot-add-error.message').toString(),
            buttonTitle: this.$t('therms.close').toString(),
          })
          return
        }

        await this.$router.back()
      } catch (err) {
        this.error = true
        throw err
      } finally {
        this.loader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

</style>
